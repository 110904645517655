<template>
<form @submit.prevent="">
    <div class="form-row">
        <div class="col-12">
            <input type="text" id="title" placeholder="عنوان ویجت" class="widget-input input-no-bg input-lg input-set-border w-100" v-model="data.title">
        </div>
    </div>
    <div class="form-row text-center mt-4">
        <div class="col-12">
            <a href="" @click.prevent="saveSettings()" class="m-left min-w150 tgju-btn tgju-btn-lg tgju-btn-primary"><i class="uil uil-save"></i> ذخیره</a>
        </div>
    </div>
</form>
</template>

<script>
// این کامپوننت مربوط به محتوای ویجت های تنظیمات (تقویم اقتصادی) است
export default {
    name: 'SettingsCalendar',
    components: {},
    props: ['data'],
    data: function () {
        return {}
    },
    methods: {
        // این متد جهت ارسال اطلاعات داخل مودال به کلاس یا واسط پرنت می باشد(همون کال باک های معروف)
        saveSettings() {
            let data = this.$helpers.unbindObject(this.data);
            this.$parent.saveSettings(data);
        },
    },
}
</script>
